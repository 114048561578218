/* Chat 스타일링 */
.chat-box {
    background-color: #f9f9f9;
    border-radius: 10px;
    /* padding: 10px; */
    width: 100%;  /* 부모 컴포넌트의 너비에 맞추기 */
    height: 100%; /* 부모 컴포넌트의 높이에 맞추기 */
    /* max-width: 600px; */
    /* margin: 0 auto; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
  }
  .chat-header {
    padding: 20px;
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    flex-shrink: 0; /* 고정 */
  }
  
  .messages-list {
    max-height: 400px;
    overflow-y: auto;
    padding: 0px 20px 10px 20px;
    display: flex;
    flex-direction: column; /* 메시지를 세로로 쌓이도록 설정 */
  }
  
  /* User 메시지 스타일링 */
  .user-message {
    background-color: #dcf8c6;
    color: #000;
    border-radius: 15px 15px 0 15px;
    padding: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    max-width: 80%;
    position: relative;
    display: block; /* 각 메시지를 새로운 행에 배치 */
    line-height: 1.43;
    
  }
  
  /* AI 메시지 스타일링 */
  .ai-message {
    background-color: #ececec;
    color: #000;
    border-radius: 15px 15px 15px 0;
    padding: 7px;
    margin-bottom: 10px;
    max-width: 80%;
    position: relative;
    display: block; /* 각 메시지를 새로운 행에 배치 */
    line-height: 1.43; /* 텍스트 줄 간격 조정 */
  }
  
  /* 메시지 폼 스타일링 */
  .message-form {
    display: flex;
    align-items: center;
    gap: 1%;
  }
  
  .message-input {
    width: 80%;
    min-height: 60px;
    border-radius: 20px;
    border: 1px solid #ddd;
    margin-left: 20px;
    margin-bottom: 20px;
    padding: 20px;
    font-size: 14px;
    resize: none;
    line-height: 1.43; /* 텍스트 줄 간격 조정 */
  }
  
  .send-button {
    margin-left: 1%;
    width: 10%;
    height: 50px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
  }
  
  .send-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  