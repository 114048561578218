.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  table {
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  
  thead th {
    background-color: #f4b183;
    padding: 10px;
    font-weight: bold;
    border: 1px solid #000;
  }
  
  .checkbox-cell {
    width: 50px;
  }
  
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
  
  tbody td {
    border: 1px solid #000;
    padding: 10px;
    font-size: 12px;
    min-width: 120px; /* 셀의 최소 너비 설정 */
  }
  
  /* TextField 스타일 조정 */
  .MuiInputBase-root {
    width: 100%; /* 셀 내부에서 TextField가 전체 너비를 차지하도록 설정 */
  }