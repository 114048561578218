.CS_MyTable {
    width: 100%;
    border-collapse: collapse; /* 이 속성은 테두리를 겹치게 합니다 */
    font-family:inherit;
  }
  
.CS_MyTable th, .CS_MyTable td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
    word-wrap: break-word; /* 텍스트가 셀 너비에 맞춰 줄바꿈됨 */
    word-break: break-all; /* 긴 단어도 강제로 줄바꿈 */
    vertical-align: top; /* 텍스트를 위쪽으로 정렬 */
    white-space: pre-wrap; /* 공백과 줄바꿈을 유지하여 표시합니다 */
    font-size: 14px; /* 글자 크기 통일 */
    line-height: 1.5; /* 줄 높이 통일 */
}
.text-cell {
  padding: 8px;
  white-space: pre-wrap; /* 공백과 줄바꿈을 유지하여 텍스트 표시 */
  word-break: break-word;
}

.editable-textarea-style {
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
  padding: 8px;
  box-sizing: border-box;
  font-family: inherit;
  border-radius: 4px;
  color:#333;
  /* background-color: rgb(237, 245, 254); */
  /* top: 0; left: 0 ; bottom:0; right:0; */
  max-height: 300px;
  /* overflow: 'auto'; */
  line-height: 1.5;

}
.editable-textarea {
  width: 100%;
  height: 100%;
  padding: 8px;
  font-size: 13px !important; /* 글자 크기 우선 적용 */
  font-family: Arial, sans-serif !important; /* 글꼴 통일 우선 적용 */
  line-height: 1.5 !important; /* 줄 높이 우선 적용 */
  box-sizing: border-box; /* 패딩을 포함한 크기 설정 */
  resize: none; /* 사용자가 크기를 조절하지 못하도록 설정 */
  overflow: hidden; /* 높이를 셀의 내용에 맞추기 위해 overflow 숨김 */
}
.CS_MyTable th {
    background-color: #BDD7EE;
  }
.CS_MyTable .column-style {
    background-color: #F8CBAD;
  }

.CS_MyTable th:nth-child(1),
.CS_MyTable td:nth-child(1) {
    /* width: 10%; 첫 번째 컬럼 너비 */
    width: 150px; /* 첫 번째 컬럼 너비 */
    }

.CS_MyTable th:nth-child(2),
.CS_MyTable td:nth-child(2) {
    width: 450px; /* 두 번째 컬럼 너비 */
    }

.CS_MyTable th:nth-child(3),
.CS_MyTable td:nth-child(3) {
    /* width: 20%; 세 번째 컬럼 너비 */
    width: 300px; /* 세 번째 컬럼 너비 */
    }

.CS_MyTable th:nth-child(4),
.CS_MyTable td:nth-child(4) {
    /* width: 20%; 네 번째 컬럼 너비 */
    width: 300px; /* 네 번째 컬럼 너비 */
    }

.CS_MyTable th:nth-child(5),
.CS_MyTable td:nth-child(5) {
    /* width: 10%; 다섯 번째 컬럼 너비 */
    /* width: 100px; */
    }
