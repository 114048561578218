.MyTable {
    width: 100%;
    border-collapse: collapse; /* 이 속성은 테두리를 겹치게 합니다 */
  }
  
.MyTable th, .MyTable td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
    font-size: 14px;
  }

.MyTable th {
    background-color: #BDD7EE;
    font-weight: 600;
  }
.MyTable .column-style {
    background-color: #F8CBAD;
  }

.MyTable th:nth-child(1),
.MyTable td:nth-child(1) {
    /* width: 10%; 첫 번째 컬럼 너비 */
    width: 150px; /* 첫 번째 컬럼 너비 */
    }

.MyTable th:nth-child(2),
.MyTable td:nth-child(2) {
    width: 450px; /* 두 번째 컬럼 너비 */
    }

.MyTable th:nth-child(3),
.MyTable td:nth-child(3) {
    /* width: 20%; 세 번째 컬럼 너비 */
    width: 300px; /* 세 번째 컬럼 너비 */
    }

.MyTable th:nth-child(4),
.MyTable td:nth-child(4) {
    /* width: 20%; 네 번째 컬럼 너비 */
    width: 300px; /* 네 번째 컬럼 너비 */
    }

.MyTable th:nth-child(5),
.MyTable td:nth-child(5) {
    /* width: 10%; 다섯 번째 컬럼 너비 */
    /* width: 100px; */
    }