.progress-bar {
    display: flex;
  }
  
  .step {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    cursor: pointer;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    background-color: #cccccc90; /* 기본 배경색 */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
  }
  
  .step.active {
    background-color: #007bff; /* 활성화된 단계의 배경색 */
    color: #fff; /* 활성화된 단계의 글자색 */
  }
  
  .step.completed {
    /* background-color: #94c1eea3; 완료된 단계의 배경색 */
    background-color: #e7eff8a3; /* 완료된 단계의 배경색 */
    color: #000; /* 완료된 단계의 글자색 */
  }