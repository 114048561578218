@font-face {
  font-family: "Pretendard-Regular";
  font-weight: normal;
  src: url("./fonts/Pretendard-Regular.otf") format("truetype");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 100; /* Thin */
  src: url("./fonts/Pretendard-Thin.otf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 300; /* Light */
  src: url("./fonts/Pretendard-Light.otf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 400; /* Regular */
  src: url("./fonts/Pretendard-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 500; /* Medium */
  src: url("./fonts/Pretendard-Medium.otf") format("truetype");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 600; /* SemiBold */
  src: url("./fonts/Pretendard-SemiBold.otf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 700; /* Bold */
  color:"#333";
  src: url("./fonts/Pretendard-Bold.otf") format("truetype");
}

body {
  margin: 0;
  font-family: "Pretendard-Regular",'Arial', 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
