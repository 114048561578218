div.window-container {
  position: absolute;
  /* border: solid 1px black; */
  background-color: #f9f9f9;
  --tilebar-height: 32px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

div.window-container > div.title-bar {
  background-color: #1976D2;
  height: var(--tilebar-height);
  display: flex;
  flex-direction: row;
  cursor: default;
  position: static;
}

div.window-container > div.title-bar > span.icon {
  line-height: var(--tilebar-height);
  height: var(--tilebar-height);
  width: var(--tilebar-height);
  background-color: #1976D2;
  font-size: calc(var(--tilebar-height) - 8px);
}

div.window-container > div.title-bar > span.windowTitle {
  flex-grow: 1;
  overflow: hidden;
  text-align: center;
  background-color: #1976D2;
  color: white;
  font-size: calc(var(--tilebar-height) - 16px);
  line-height: var(--tilebar-height);
  font-family: "Pretendard-Regular",'Arial', 'Roboto';
  font-weight: regular;
}

div.window-container > div.title-bar > span.windowTitle:active {
  opacity: 0.5;
}

div.window-container > div.title-bar > span.buttonContainer {
  display: flex;
  flex-direction: row;
}

div.window-container
  > div.title-bar
  > span.buttonContainer
  > span.windowButton {
  height: var(--tilebar-height);
  width: var(--tilebar-height);
  font-size: calc(var(--tilebar-height) - 8px);
  cursor: default;
  color: black;
  transition: 200ms ease-in-out;
}

div.window-container
  > div.title-bar
  > span.buttonContainer
  > span.windowButton:hover {
  color: white;
}

div.window-container > div.content {
  all: initial;
  background-color: white;
  overflow-y: scroll;
}
