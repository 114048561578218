.table-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 100vh; */
    height: 100%;
    margin: 0;
    padding: 0;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;
  }

.tableHeader {
    background-color: #BDD7EE;
    color: #000;
    font-size: 16px;
    font-weight: bold;
    padding: 8px;
    text-align: center;
  }

  
  .tableCell {
    padding: 10px;
    text-align: center;
    border: 1px solid #ccc;
    font-size:14px;
  }
  
  .tableRow {
    background-color: #fff;
  }
  