.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 100vh; */
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  table {
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;
  }
  
  td {
    border: 1px solid #000;
    padding: 10px;
    vertical-align: middle; /* 수직 가운데 정렬 */
    text-align: left; /* 기본 왼쪽 정렬 */
  }
  
  .category-cell {
    width: 20%;
    text-align: center; /* 제목은 가운데 정렬 */
  }
  
  .details-cell {
    width: 30%;
  }
  
  .description-cell {
    width: 50%;
  }
  